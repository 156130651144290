import {
  initializeAppCheck,
  getToken,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import firebaseApp from "../services/firebase";

const appCheck = (recaptchaToken: string) => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  return initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(recaptchaToken),
  });
};

export const getAppCheckToken = async (recaptchaToken: string, creatCookie: boolean) => {
  try {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      return process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN as string;
    } else {
      return (await getToken(appCheck(recaptchaToken), creatCookie)).token;
    }
  } catch (err) {
    throw err;
  }
};
