import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../services/firebase";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import { useAuthState } from "react-firebase-hooks/auth";

interface Props {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: Props) => {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (user === null && loading === false) {
        navigate("/logga-in");
      }
      if (user) {
        const admin = (await (
          await user.getIdTokenResult()
        ).claims.admin) as boolean;
        if (admin !== true) {
          navigate("/logga-ut");
        }
      }
    })();
  }, [user, loading]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      {loading || user === undefined ? null : children}
    </>
  );
};
