import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import { Typography } from "@mui/material";

const DashboardScreen = () => {
  return (
    <ContentAreaWrapper>
      <Typography variant="h1" sx={{ fontSize: "1.5rem" }}>Här kommer synas statistik m.m</Typography>
    </ContentAreaWrapper>
  );
};

export default DashboardScreen;
