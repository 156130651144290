import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import { Typography } from "@mui/material";

const SchoolsScreen = () => {
  return (
    <ContentAreaWrapper>
      <Typography variant="h1" sx={{ fontSize: "1.5rem" }}>
        Finns inga skolor ännu
      </Typography>
    </ContentAreaWrapper>
  );
};

export default SchoolsScreen;
