import { useContext, useState } from "react";
import { CompaniesContext } from "../context/companiesContext";
import { useHeaders } from "./useHeaders";
import {
  CompaniesStatisticsResponse,
  PaginatedCompanyResponse,
} from "../api/companyCollection.api";
import axios, { AxiosError } from "axios";
import { getAppCheckToken } from "../utils/appCheck";

const useCompanies = () => {
  const { baseHeaders } = useHeaders();
  const { companyPage, setCompanyPage, statistics, setStatistics } =
    useContext(CompaniesContext);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companiesError, setCompaniesError] = useState<null | Error>(null);

  const getStatistics = async (
    token?: string,
    municipality?: string
  ): Promise<CompaniesStatisticsResponse | undefined> => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            true
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      setLoadingCompanies(true);
      try {
        const { data }: { data: CompaniesStatisticsResponse } = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/statistics${
            municipality ? `?municipality=${municipality}` : ""
          }`,
          {
            headers: headers,
          }
        );
        setLoadingCompanies(false);
        setStatistics(data);
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 404) {
          return undefined;
        } else {
          setCompaniesError(err as Error);
        }
      }
    }
  };

  const getCompanies = async (
    limit: number,
    offset: number,
    token?: string,
    isApproved?: "true" | "false" | undefined
  ): Promise<PaginatedCompanyResponse | undefined> => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            true
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      try {
        setLoadingCompanies(true);
        const { data }: { data: PaginatedCompanyResponse } = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/companies/all?limit=${limit}&offset=${offset}${
            isApproved ? `&isApproved=${isApproved}` : ""
          } `,
          {
            headers: headers,
          }
        );
        setCompanyPage(data);
        setLoadingCompanies(false);
        return data as PaginatedCompanyResponse;
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 404) {
          return undefined;
        } else {
          setCompaniesError(err as Error);
        }
      }
    }
  };

  return {
    companyPage,
    getCompanies,
    statistics,
    getStatistics,
    loadingCompanies,
    companiesError,
  };
};

export default useCompanies;
