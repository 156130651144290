import Grid from "@mui/material/Grid/Grid";
import React, { useEffect, useState } from "react";
import KommunAutoCompleteField from "../commons/KommunAutoCompleteField";
import { Option } from "../../types/common";
import { Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";
import useCompanies from "../../hooks/useCompanies";

export const StatsBar = () => {
  const [user] = useAuthState(auth);
  const { statistics, getStatistics } = useCompanies();
  const [selectedKommun, setSelectedKommun] = useState<Option<string> | null>({
    label: "Alla",
    id: "all",
  });

  useEffect(() => {
    if (user) {
      if (statistics === undefined) {
        user.getIdToken().then((idToken) => {
          getStatistics(idToken);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (statistics === undefined) {
      return;
    }

    if (user) {
      user.getIdToken().then((idToken) => {
        getStatistics(
          idToken,
          selectedKommun?.id === "all" ? undefined : selectedKommun?.id
        );
      });
    }
  }, [selectedKommun, user]);

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={8}>
        <Typography>
          Antal aplplatser: {statistics?.numberOfPraoPositions}
        </Typography>
        <Typography>Antal företag: {statistics?.numberOfCompanies}</Typography>
      </Grid>
      <Grid item xs={8}>
        <KommunAutoCompleteField
          selectedKommun={selectedKommun}
          setSelectedKommun={setSelectedKommun}
          label="Kommun"
          name="kommun"
          runValidation={"true"}
          kommunError={null}
          setKommunError={() => console.log("setKommunError")}
          includeAllAsOption={true}
        />
      </Grid>
    </Grid>
  );
};
