import { useEffect, useState } from "react";
import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import {
  Box,
  Card,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useCompanies from "../hooks/useCompanies";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { LoadingOverlay } from "../components/commons/LoadingOverlay";
import { StatsBar } from "../components/companies/StatsBar";
import { municipalityToView } from "../utils/common";
import { Checkbox } from "@mui/material";

const CompaniesScreen = () => {
  const [user] = useAuthState(auth);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 20;

  const { companyPage, getCompanies, loadingCompanies, companiesError } =
    useCompanies();

  useEffect(() => {
    if (user) {
      if (companyPage === undefined) {
        user.getIdToken().then((idToken) => {
          getCompanies(limit, 0, idToken);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (companyPage === undefined) {
      return;
    }

    if (companyPage.total !== totalPages) {
      setTotalPages(Math.ceil(companyPage.total / limit));
    }

    const offset = page * limit - limit;
    if (offset !== companyPage.offset) {
      if (user) {
        user.getIdToken().then((idToken) => {
          getCompanies(limit, offset, idToken);
        });
      }
    }
  }, [companyPage, page, user]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <ContentAreaWrapper>
      <LoadingOverlay loading={loadingCompanies} />
      <Box sx={{ paddingY: "20px" }}>
        <Card sx={{ padding: "20px" }}>
          <Typography
            variant="h1"
            sx={{ fontSize: "1.5rem", paddingBottom: "20px" }}
          >
            Statistik:
          </Typography>
          <StatsBar />
        </Card>
      </Box>
      <Box sx={{ paddingY: "20px" }}>
        <Card sx={{ padding: "20px" }}>
          <Typography
            variant="h1"
            sx={{ fontSize: "1.5rem", paddingBottom: "20px" }}
          >
            Företag:
          </Typography>
          <Box sx={{ paddingBottom: "20px" }}>
            {companyPage?.data.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Namn</TableCell>
                      <TableCell align="right">Kommun</TableCell>
                      <TableCell align="right">Antal aplplatser</TableCell>
                      <TableCell align="right">Godkänd</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyPage.data.map((company) => (
                      <TableRow
                        key={company.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {company.name}
                        </TableCell>
                        <TableCell align="right">
                          {municipalityToView(company.municipality)}
                        </TableCell>
                        <TableCell align="right">
                          {company.numberOfPraoPositions}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox checked={company.isApproved} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h1" sx={{ fontSize: "1.5rem" }}>
                Finns inga företag ännu.
              </Typography>
            )}
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
          />
          {companiesError && (
            <Typography sx={{ color: "red" }}>
              {companiesError.message}
            </Typography>
          )}
        </Card>
      </Box>
    </ContentAreaWrapper>
  );
};

export default CompaniesScreen;
