import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import { auth } from "../../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

interface Props {
  children: ReactNode;
}

export const PublicOnlyRoute = ({ children }: Props) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      {children}
    </>
  );
};
