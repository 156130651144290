import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginScreen from "./screens.tsx/LoginScreen";
import { ProtectedRoute } from "./components/actions/routing/ProtectedRoute";
import { PublicOnlyRoute } from "./components/actions/routing/PublicOnlyRoute";
import HeaderProtectedView from "./components/base/HeaderProtectedView";
import DashboardScreen from "./screens.tsx/DashboardScreen";
import FiebaseSignOutAction from "./components/actions/oauth/FiebaseSignOutAction";
import NotFoundScreen from "./screens.tsx/NotFoundScreen";
import CompaniesScreen from "./screens.tsx/CompaniesScreen";
import SchoolsScreen from "./screens.tsx/SchoolsScreen";
import RequestHeadersProvider from "./context/requestHeadersContext";
import CompaniesProvider from "./context/companiesContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <DashboardScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/foretag",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <CompaniesScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/skolor",
    element: (
      <ProtectedRoute>
        <HeaderProtectedView />
        <SchoolsScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/logga-in",
    element: (
      <PublicOnlyRoute>
        <LoginScreen />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/logga-ut",
    element: (
      <>
        <FiebaseSignOutAction />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <NotFoundScreen />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RequestHeadersProvider>
      <CompaniesProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </CompaniesProvider>
    </RequestHeadersProvider>
  </React.StrictMode>
);
