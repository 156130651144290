import { Box } from "@mui/material";
import NavBar from "./Navbar";

const HeaderProtectedView = () => {
  return (
    <Box>
      <NavBar />
    </Box>
  );
};

export default HeaderProtectedView;
