import kommuner from "../data/kommuner";

export const pickRandomItems = <T>(list: T[], count: number): T[] => {
  if (count > list.length) {
    throw new Error("Count cannot be greater than the list length.");
  }

  const randomItems: T[] = [];
  const copyList = list.slice();

  while (randomItems.length < count) {
    const randomIndex = Math.floor(Math.random() * copyList.length);
    const selectedItem = copyList.splice(randomIndex, 1)[0];

    randomItems.push(selectedItem);
  }

  return randomItems;
};

export const serializedPhoneToView = (phone: string): string => {
  const withoutPrefix = phone.slice(4);
  const withZero = "0" + withoutPrefix;
  const withDash = withZero.slice(0, 3) + "-" + withZero.slice(3);
  const withSpaces =
    withDash.slice(0, 7) + " " + withDash.slice(7, 9) + " " + withDash.slice(9);
  return withSpaces;
};

export const municipalityToView = (municipalityCode: string): string => {
  const kommun = kommuner.find(
    (municipality) => municipality.kommunkod === municipalityCode
  );
  if (kommun === undefined) {
    return "";
  }
  return kommun.namn;
};

export const organizationNumberToView = (
  organizationNumber: string
): string => {
  const withDash =
    organizationNumber.slice(0, 6) + "-" + organizationNumber.slice(6);
  return withDash;
};
