import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { AuthErrorCode, mapAuthErrorCodeToText } from "../utils/firebaseAuth";
import { isValidEmail } from "../utils/validations";
import { LoadingOverlay } from "../components/commons/LoadingOverlay";

function LoginScreen() {
  const [signInWithEmailAndPassword, signInUser, signInLoading, signInError] =
    useSignInWithEmailAndPassword(auth);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState<null | string>(null);
  const [error, setError] = React.useState<null | string>(null);

  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error !== null) {
      setError(null);
    }
    if (emailError !== null) {
      setEmailError(null);
    }
    const { value } = event.target;
    setEmail(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error !== null) {
      setError(null);
    }
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validEmail = isValidEmail(email);

    if (validEmail === false) {
      return;
    }

    await signInWithEmailAndPassword(email, password);
    if (signInUser) {
      navigate("/");
    }
    if (signInError) {
      setError(mapAuthErrorCodeToText(signInError.code as AuthErrorCode));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        background:
          "linear-gradient(35deg, rgb(241, 89, 90) 0%, rgb(236, 194, 123) 73%)",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <LoadingOverlay loading={signInLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          gap: "15px",
          rowGap: "15px",
          maxWidth: "400px",
          margin: "auto",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <Box>
          <Typography variant="h2" fontSize={"2em"}>
            Logga in
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "15px",
            }}
          >
            <TextField
              label="Epost"
              name="email"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              label="Lösenord"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            {error && (
              <FormHelperText error={error !== null}>{error}</FormHelperText>
            )}
            <Button sx={{ flex: 1 }} type="submit" variant="contained">
              Logga in
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default LoginScreen;
