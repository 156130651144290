import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { RequestHeadersContext } from "../context/requestHeadersContext";
import { getAppCheckToken } from "../utils/appCheck";
import { auth } from "../services/firebase";

export const useHeaders = () => {
  const [user] = useAuthState(auth);
  const [baseHeadersWithAuth, setBaseHeadersWithAuth] = useState<any>(null);

  const { requestHeaders, setRequestHeaders, validUntil } = useContext(
    RequestHeadersContext
  );

  useEffect(() => {
    if (requestHeaders !== undefined) {
      setBaseHeadersWithAuth(requestHeaders);
    }
  }, [requestHeaders]);

  useEffect(() => {
    if (user) {
      const nowPreFetch = new Date(Date.now());
      if (validUntil) {
        if (validUntil.current.getTime() < nowPreFetch.getTime())
          (async () => {
            const idToken = await user.getIdToken();
            const headers = {
              "X-Firebase-AppCheck": await getAppCheckToken(
                process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
                true
              ),
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            };
            const nowPostFetch = new Date(Date.now());
            const expirationTime = new Date(
              nowPostFetch.getTime() + 5 * 60 * 1000
            );
            validUntil.current = expirationTime;
            setRequestHeaders(headers);
          })();
      }
    }
  }, [user, validUntil]);

  return { baseHeaders: baseHeadersWithAuth };
};
